import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import 'bootstrap/dist/css/bootstrap.min.css';
import SoupHomepage from "../../components/products-soup/SoupHomepage";

export default function Home() {
  return (
    <Layout>
      <section>
        <div>
          {/* For page head */}
          <Helmet>
                <title>Maker Recipe Sdn. Bhd.-Soup</title>
          </Helmet>

          <SoupHomepage />
        </div>
      </section>
    </Layout>
    
  )
}